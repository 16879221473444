@mixin mod-chosen-select(){
    /*Start Module: Chosen Select*/
    .chosen-container{
        width: 100% !important;
        display: inline-flex;
        @include rem-fallback(font-size, 15);
        @include rem-fallback(letter-spacing, 0.5);
        color: $theme-color-primary;

        .chosen-drop{
            border-top-width: 1px;
            box-shadow: none;
        }

        .chosen-results{
            @include rem-fallback(font-size, 15);
            @include rem-fallback(letter-spacing, 0.5);
            line-height: normal;
            font-family: $font-primary-bold;
            color: $text-color-dark;
            // padding: 0 4px;
            margin: 6px 0;
            max-height: 400px;

            li{
                padding: 0;
                @include rem-fallback(font-size, 15);
                @include rem-fallback(letter-spacing, 0.5);
                line-height: normal;
                font-weight: normal;
                text-align: left;

                &.highlighted{
                    color: $text-color-dark;
                    background-image: none;
                    // color: $theme-color-primary;
                }

                &.result-selected{
                    color: $text-color-dark;
                    // font-weight: bold;
                }

                &.result-invisible{
                    display: none;
                }
            }
        }
    }

    .chosen-container-single{
        .chosen-single{
            border: none;
            border-bottom: none;
            border-radius: 0;
            background: transparent;
            box-shadow: none;
            padding-left: 4px;
            color: $text-color-dark;
            // transition: all $transition-delay $transition-mode;
            input[type="text"] {
                width: 100%;
                left: 0;
                border: none;
                border-bottom: none;
                background-color: transparent;
                @include rem-fallback(font-size, 15);
                @include rem-fallback(letter-spacing, 0.5);
                // padding-left: 6px;
                // padding-right: 6px;
                // color: #2f476f;
                -webkit-outline: none;
                -moz-outline: none;
                -ms-outline: none;
                -o-outline: none;
                outline: none;
                transition: all 300ms ease-in-out;
            }
            span{
                min-width: 25px;
                font-family: $font-primary-bold;
                font-weight: normal;
            }
            div{
                b{
                    background: none;
                    position: relative;

                    &:after{
                        position: absolute;
                        display: block;
                        content: '';
                        width: 16px;
                        height: 9px;
                        top: 8px;
                        left: 3px;
                        background: url('../images/icon-drop-down.svg') no-repeat;
                        background-position: right;
                        background-size: 16px 9px;
                    }
                }
            }
        }

        .chosen-drop{
            margin-top: 0;
            border-radius: 0;
            border-color: #eaecf0;
        }
    }

    .chosen-container-multi{
        .chosen-drop{
            .result-selected{
                background-color: $theme-color-primary;
                color: white;
            }
        }
    }

    .chosen-container-active{
        .chosen-single{
            border-bottom: none;
        }
        
        &.chosen-with-drop{
            .chosen-single{
                border: none;
                border-bottom: none;
                background-image: none;
                box-shadow: none;

                div{
                    b{
                        // &:before{
                        //     @include prefix(transform, rotate(-45deg));
                        // }
                        
                        &:after{
                            @include prefix(transform, rotate(-180deg));
                        }
                    }
                }
            }
        }
    }
    /*End Module: Chosen Select*/
}