@mixin mod-data-table(){
    /*Start Module: Data Tables*/
    .data-table-container{
        .dataTables_wrapper{
            // background-color: red;
            margin: 0px -20px 0;
            // table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd
            table{
                thead{
                    tr{
                        background-color: #afafaf;
                    }
                    th{
                        &.selectable-item{
                            text-align: center;
                        }
                        &.headerRow{
                            margin: 0 auto;
                        }
                        border-bottom: none;
                        color: #ffffff;
                        @include rem-fallback(font-size, 16);
                        font-family: $font-primary-bold;
                        letter-spacing: 0;
                        padding: 10px;
                        &:not(:last-child){
                            border-right: 1px solid #ffffff;
                        }
                    }
                }
                &.data-table{
                    &.display{
                        tr{
                            &.odd{
                                background-color: #ffffff;
                            }
                        }
                    }
                    &.stripe{
                        tbody{
                            tr{
                                &.odd{
                                    background-color: #ffffff;
                                }
                            }
                        }
                    }
                }

                thead{
                    .sorting,
                    .sorting_asc,
                    .sorting_desc{
                        background-image: none;
                    }
                }
                
                tbody{
                    tr{
                        color: $text-color-dark;
                        &.even{
                            background-color: #ebebeb;
                        }
                    }
                    td{
                        border-bottom: none;
                        font-family: $font-primary;
                        @include rem-fallback(font-size, 16);
                        @include rem-fallback(line-height, 40);
                        padding: 0 10px;

                        &:not(:last-child){
                            border-right: 1px solid #afafaf;
                        }

                        &.selectable-item{
                            text-align: center;
                        }

                        &.txt-bold{
                            font-family: $font-primary-bold;
                        }
                    }
                }

                &.non-selectable-table{
                    th:first-child,
                    td:first-child{
                        padding-left: $side-padding-tablet;
                    }
                }
            }
            &.no-footer{              
                .dataTables_scrollBody{
                    border-bottom: none;
                }
            } 
            .dataTables_paginate,
            .dataTables_length,
            .dataTables_info{
                float: none;
                display: flex;
                align-self: center;
                padding-top: 0;
            }
            .dataTables_length{
                order: -1;
                padding-top: 0px;
                @include rem-fallback(font-size, 15);
                @include rem-fallback(letter-spacing, 0.5);
                label{
                    font-family: $font-primary;
                    @include rem-fallback(font-size, 15);
                    @include rem-fallback(letter-spacing, 0.5);
                    display: flex;
                    align-items: center;
                    color: $text-color-dark;
                    margin-bottom: 0;
                }
    
                select{
                    font-family: $font-primary-bold;
                    border: none;
                }
            }
            .dataTables_paginate{
                .paginate_button{
                    min-width: 9px;
                    padding: 0;
                    line-height: 16px;
                    margin-left: 10px;
                    &.previous{
                        margin-right: 10px;
                    }
                    &:hover{
                        background: transparent;
                        border: 1px solid transparent;
                    }
                }
            } 
            .dataTables_info{
                font-family: $font-primary-bold;
                @include rem-fallback(font-size, 15px);
                letter-spacing: 0.5px;
                margin-left: 60px;
                color: $text-color-dark;
            }
            table.dataTable.display tbody tr.odd,
             table.dataTable.order-column.stripe tbody tr.odd{
                 .sorting_1{
                     background-color: #ffffff;
                 }
            }
            table.dataTable.display tbody tr.even,
            table.dataTable.order-column.stripe tbody tr.even{
                .sorting_1{
                    background-color: #ebebeb;
                }
            }
            .bottom{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-content: center;
                text-align: center;
                background-color: #fff;
                min-height: 50px;
            }
            table.dataTable tr.selected td.select-checkbox:after, table.dataTable tr.selected th.select-checkbox:after{
                content: '';
                background-image: url(../images/icon-checkbox-checked.svg);
                background-repeat: no-repeat;
                background-size: 16px 16px;
                background-position: top left;
                margin-left: -10px;
                height: 16px;
                width: 16px;
                display: inline-block;
                line-height: 1;
                vertical-align: middle;
                border: none;
                margin-top: -6px;
            }

            table.dataTable tr.selected th.select-checkbox:after{
                margin-left: -18px;
                margin-top: -5px;
            }

            table.dataTable tbody td.select-checkbox:before, table.dataTable tbody td.select-checkbox:after, table.dataTable tbody th.select-checkbox:before, table.dataTable tbody th.select-checkbox:after{
                // background-image: url(../images/icon-checkbox-unchecked.svg);
                // background-repeat: no-repeat;
                // background-size: 16px 16px;
                // background-position: top left;
                // margin-left: -18px;
                // min-height: 16px;
                // width: 16px;
            }
            
            table.dataTable tbody td.select-checkbox:before, table.dataTable tbody th.select-checkbox:before{
                background-image: url(../images/icon-checkbox-unchecked.svg);
                background-repeat: no-repeat;
                background-size: 16px 16px;
                background-position: top left;
                margin-left: -10px;
                height: 16px;
                width: 16px;
                display: inline-block;
                line-height: 1;
                vertical-align: middle;
                border: none;
            }

            .dataTables_filter{
                margin:0 20px 40px 0;
                input{
                    min-width: 260px;
                    border: none;
                    border-bottom:1px solid #afafaf;
                    background: url('../images/icon-search.svg') no-repeat transparent;
                    background-size: 26px 23px;
                    background-position: right;
                    color: #666666;
                    min-height: 23px;
                    background-position: right;
                    display: inline-block;
                    vertical-align: middle;
                    line-height: 1;
                    padding: 15px 30px 15px 0;
                    @include rem-fallback(font-size, 15);
                    @include rem-fallback(letter-spacing, 0.5);
                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: #666666;
                    }
                    &::-moz-placeholder { /* Firefox 19+ */
                        color: #666666;
                    }
                    &:-ms-input-placeholder { /* IE 10+ */
                        color: #666666;
                    }
                    &:-moz-placeholder { /* Firefox 18- */
                        color: #666666;
                    }
                }
            }
        }
    }
    /*End Module: Data Tables*/
}