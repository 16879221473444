@mixin mod-header(){
    /*Start Module: Header*/
    .header{
        background-color: $theme-color-primary;
        background-image: linear-gradient(to left, #850000, #fa0000);

        &--content{
            @include display-flex();
            justify-content: space-between;
            padding-top: 2 * $side-padding-desktop;
            padding-bottom: 5 * $side-padding-desktop;

            &--right{
                &--wrap{
                    @include display-flex();
                    color: white;
                }
            }

            &--details{
                @include rem-fallback(font-size, 17);
                font-family: $font-primary-bold;
                margin-left: 8px;
                padding-top:8px;

                &-name{
                    display: block;
                    margin-bottom: 5px;
                }

                &-data-link{
                    color: white;
                    @include rem-fallback(font-size, 12);
                    font-family: $font-primary;
                    text-transform: uppercase;
                }

                &-logout-link{
                    border-top: 2px solid white;
                    margin-top: 18px;
                    padding-top: 16px;

                    a{
                        text-decoration: underline;
                        display: inline-block;

                        &:hover{
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        &--logo{
            background-image: url('../images/icon-logo-vodafone.svg');
            background-repeat: no-repeat;
            @include rem-fallback(font-size, 35);
            font-family: $font-primary-bold;
            color: white;
            display: inline-block;
            padding: 17px 0 18px 91px;

            &:hover{
                text-decoration: none;
                color: white;
            }
        }

        .hamburger{
            display: none;
        }
    }
    /*End Module: Header*/
}

@mixin mod-header-tablet(){
    /*Start Module: Header - Tablet*/
    .header{
        &--content{
            flex-direction: column;
            padding-bottom: 40px;

            &--right{
                background-color: black;
                margin: 0 -20px 0;
                padding: 0px 20px 100px;
                position: absolute;
                width: 100%;
                top: 100%;
                z-index: 10;
                display: none;

                &.active{
                    display: block;
                }

                &--wrap{
                    padding: 50px 0;
                }
            }

            &--details-logout-link{
                background-color: black;
                border-width: 1px;
                position: absolute;
                width: 100%;
                // top: 0;
                left: 0;
                text-align: right;
                padding: 1.5 * $side-padding-desktop $side-padding-desktop 2 * $side-padding-desktop;
                bottom: 0;

                a{
                    text-transform: uppercase;
                    padding-right: 50px;
                    background-image: url('../images/icon-logout.svg');
                    background-repeat: no-repeat;
                    background-position: right center;
                    line-height: 32px;
                    text-decoration: none;

                    &:hover{
                        text-decoration: none;
                    }
                }
            }

        }

        .hamburger{
            display: flex;
            align-items: center;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            padding: 20px 20px 20px 30px;

            button{
                padding: 0;
                border: none;
                width: 22px;
                height: 22px;
                background-color: transparent;

                span{
                    display: block;
                    height: 2px;
                    background-color: white;
                    transition: all $transition-duration $transition-timing-function;

                    &:not(:last-child){
                        margin-bottom: 5px;
                    }
                }

                &:focus{
                    outline: none;
                }
            }

            &.active{
                background-color: black;

                button{
                    span{
                        opacity: 0;
                        transition: all $transition-duration $transition-timing-function;

                        &:first-child,
                        &:last-child{
                            opacity: 1;
                            width: 24px;
                            height: 3px;
                            @include prefix(transform, rotate(45deg));
                            margin: 0;
                            transform-origin: 21% 21% 0;
                            
                        }

                        &:last-child{
                            @include prefix(transform, rotate(-45deg));
                        }
                    }
                }
            }
        }
    }
    /*End Module: Header - Tablet*/
}