@import "../modules/common/modules";
@import "variables";
@import "vendor";
@import "common";

//Common modules
@include mod-buttons();
@include mod-modals();

//Component modules
@include mod-header();
@include mod-menu();
@include mod-utilities();
@include mod-page-title();
@include mod-section-title();
@include mod-forms();
@include mod-inputs();
@include mod-data-table();
@include mod-operation-complete();
@include mod-chosen-select();
@include mod-progress();


@media all and (max-width: ($viewport-width-desktop - 1) + px){
    //Common modules
    @include mod-buttons-tablet();
    @include mod-modals-tablet();

    //Component modules
    @include mod-header-tablet();
    @include mod-menu-tablet();
    @include mod-utilities-tablet();
    @include mod-page-title-tablet();
    @include mod-forms-tablet();
    @include mod-operation-complete-tablet();
}

/*Tablet Landscape viewport for Login page*/
@media all and (min-width: $viewport-login-width-tablet-landscape + px) and (max-width: ($viewport-login-width-desktop - 1) + px){
    @include mod-inputs-tablet-landscape();
    @include mod-page-title-tablet-landscape();
    @include mod-buttons-tablet-landscape();
    @include mod-modals-tablet-landscape();
    
}

/*Tablet Portrait viewport for Login page*/
@media all and (min-width: $viewport-login-width-tablet-portrait + px) and (max-width: ($viewport-login-width-tablet-landscape - 1) + px){
    @include mod-inputs-tablet-portrait();
    @include mod-page-title-tablet-portrait();
    @include mod-buttons-tablet-portrait();
    @include mod-modals-tablet-portrait();
    
}

/*Mobile viewport for Login page*/
@media all and (max-width: ($viewport-login-width-tablet-portrait - 1) + px){
    @include mod-inputs-mobile();
    @include mod-page-title-mobile();
    @include mod-buttons-mobile();
    @include mod-modals-mobile();
    
            
}  