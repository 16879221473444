@mixin mod-operation-complete(){
    /*Start Module: Operation Complete*/    
    .operation-complete{
       
        &--data{
            text-align: center;
            padding: 40px 0;
            background-color: #ffffff;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

            &--status{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 45px;

                &--tick{
                    background: url('../images/icon-red-tick.svg') no-repeat center #f4f4f4;
                    border-radius: 50%;
                    width:82px;
                    height:82px;
                    padding: 30px 25px;    
                    margin-right: 10px;           
                    // display: inline-block;
                }
        
                &--heading{
                    @include rem-fallback(font-size, 30);
                    font-weight: bold;
                    letter-spacing: 0.8px;
                    // display: inline-block;
                }
            }   
            
            &--text{
                @include rem-fallback(font-size, 18);
                letter-spacing: 0.5px;
                margin-bottom: 25px;
            }

            &--text:last-child{
                margin-bottom: 0;
            }
        }

        .btn--wrap{
            justify-content: center;
        }
    }
    /*End Module: Operation Complete*/
}

@mixin mod-operation-complete-tablet(){
    /*Start Module: Operation Complete - Tablet*/
    .operation-complete{
        
    }
    /*End Module: Operation Complete - Tablet*/
}