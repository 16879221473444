@mixin mod-page-title(){
    /*Start Module: Page Title */
    .page-title{
        margin: 0 0 3 * $side-padding-desktop;

        h1{
            @include rem-fallback(font-size, 34);
            letter-spacing: 0.9px;
            margin: 0 0 15px; 
        }

        p{
            letter-spacing: 0.4px;
        }

        &--logo{
            display: inline-block;
            background: url('../images/icon-logo-with-text.svg') no-repeat center center;
            background-size: 96px 25px;
            width: 96px;
            height: 25px;
        }

        &.page-title--login{
            // background-color: red;
            margin: 0 0 4 * $side-padding-desktop;

            .page-title--logo{
                background: url('../images/icon-logo-with-text.svg') no-repeat center center;
                background-size: 96px 25px;
                display: inline-block;
                margin-bottom: 50px;
                height: 25px;
                width: 96px;
            }
            
            h1{
                color: #333333;
                font-family: $font-primary-bold; 
                @include rem-fallback(font-size, 42);
                @include rem-fallback(line-height, 42);
                letter-spacing: 1.3px;
                margin: -4px 0 10px;
            }

            p{
                color: #333333;
                @include rem-fallback(font-size, 22);
                @include rem-fallback(line-height, 22);
                letter-spacing: normal;
                margin-bottom: 0;
            }
        }        
    }
    /*End Module: Page Title */
}

@mixin mod-section-title(){
    /*Start Module: Section Title */
    .section-title{
        font-family: $font-primary-bold;
        @include rem-fallback(font-size, 22);
        letter-spacing: 0.6px;
        margin: 0 0 10px;
    }

    .section-subtitle{
        font-family: $font-primary-bold;
        @include rem-fallback(font-size, 18);
        letter-spacing: 0.5px;
        margin: 0 0 25px;
    }
    /*End Module: Section Title */
}

@mixin mod-page-title-tablet(){
    /*Start Module: Forms - Tablet*/
    .page-title{

    }
    /*End Module: Forms - Tablet*/
}

@mixin mod-page-title-tablet-landscape(){
    /*Start Module: Forms - Tablet Landscape*/
    .page-title{
        &.page-title--login{
            margin: 0 0 3 * $side-padding-tablet;
        }
    }
    /*End Module: Forms - Tablet Landscape*/
}
@mixin mod-page-title-tablet-portrait(){
    /*Start Module: Forms - Tablet Portrait*/
    .page-title{
        &.page-title--login{
            margin: 0 0 2.5 * $side-padding-tablet;
        }
    }
    /*End Module: Forms - Tablet Portrait*/
}
@mixin mod-page-title-mobile(){
    /*Start Module: Forms - Mobile*/
    .page-title{
        &.page-title--login{
            margin: 0 0 30px;
            .page-title--logo{
                margin-bottom: $side-padding-desktop;
            }

            h1{
                @include rem-fallback(font-size, 26);
                @include rem-fallback(line-height, 26);
                letter-spacing: 0.81px;
            }

            p{
                @include rem-fallback(font-size, 18);
                @include rem-fallback(line-height, 22);
            }
        }
    }
    /*End Module: Forms - Mobile*/
}

