@mixin mod-buttons(){
    /*Start Module: Buttons*/

    .btn{
        display: inline-block;
        @include rem-fallback(font-size, 14);
        font-weight: bold;
        line-height: normal;
        letter-spacing: 1px;
        color: #ffffff;
        padding: 10px 20px;
        border-radius: 4px;
        border: none;

        &--red{
            background-color: #e60000;
        }

        &--grey{
            background-color: #999999;
        }

        &:hover,
        &:active,
        &:focus{
            color: #ffffff !important;
        }

        &--wrap{
            @include display-flex();
            justify-content: space-between;
            margin-top: 20px;

            .btn:not(:last-child){
                margin-right: 20px;
            }
        }

        &.login-btn{
            font-family: $font-primary-bold;
            font-weight: normal;
            min-width: 130px;
            border-radius: 2px;
        }
    }
    /*End Module: Buttons*/
}

@mixin mod-buttons-tablet(){
    /*Start Module: Buttons - Tablet*/
    .btn{

    }
    /*End Module: Buttons - Tablet*/
}

@mixin mod-buttons-tablet-landscape(){
    /*Start Module: Buttons - Tablet Landscape*/
    .btn{
        &.login-btn{
            min-width: 105px;
            padding: 10px 15px;
        }
    } 
    /*End Module: Buttons - Tablet Landscape*/
}

@mixin mod-buttons-tablet-portrait(){
    /*Start Module: Buttons - Tablet Potrait*/
    .btn{
        &.login-btn{
            min-width: 105px;
            padding: 10px 15px;
        }
    }   
    /*End Module: Buttons - Tablet Potrait*/
}

@mixin mod-buttons-mobile(){
    /*Start Module: Buttons - Tablet Potrait*/
    .btn{
        &.login-btn{
            min-width: 280px;
            padding: 17px 0;
        }
    } 
    /*End Module: Buttons - Tablet Potrait*/
}