@mixin mod-utilities(){
    /*Start Module: Utilities*/
    .utilities{
        .row{
            display: flex;
            flex-wrap: wrap;
        }
        
        & &--item{
            margin-bottom: 30px;

            &--wrap{
                background-color: white;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                display: block;
                height: 100%;
                padding: 30px 10px 55px;
                text-align: center;

                span{
                    display: block;

                    &.title{
                        @include rem-fallback(font-size, 26);
                        letter-spacing: 0.7px;
                        margin-bottom: 25px;
                    }

                    &.icon{
                        margin-bottom: 20px;
                        min-height: 69px;
                    }

                    &.desc{
                        @include rem-fallback(font-size, 14);
                        font-family: $font-primary-bold;
                        line-height: 1.57;
                        letter-spacing: 0.4px;
                        padding: 0 15%;
                    }
                }
            }
        }
    }
    /*End Module: Utilities*/
}

@mixin mod-utilities-tablet(){
    /*Start Module: Utilities - Tablet*/
    .utilities{
        padding-left: 1.5 * $side-padding-tablet;
        padding-right: 1.5 * $side-padding-tablet;

        .row{
            margin-left: -10px;
            margin-right: -10px;
        }

        & &--item{
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 20px;

            &--wrap{
                span.desc{
                    padding: 0 1.5 * $side-padding-tablet;
                }
            }
        }
    }
    /*End Module: Utilities - Tablet*/
}