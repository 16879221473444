@mixin mod-inputs(){
    input[type="radio"]{
        &.opt-vodafone{
            opacity: 0;
            cursor: pointer;

            +label{
                background: none;
                background-image: url(../images/icon-radio-unchecked.svg);
                background-repeat: no-repeat;
                background-size: 16px;
                background-position: top left;
                padding-left: 25px;
                margin-left: -20px;
                margin-right: 60px;
                display: inline-block;
                line-height: 1;
                cursor: pointer;
            }

            &:checked{
                + label{
                    background-image: url(../images/icon-radio-checked.svg);
                    background-position: top left;
                }
            }            
        }
    }

    input[type="checkbox"]{
        &.chk-vodafone{
            opacity: 0;
            +label{
                font-family: $font-primary;
                font-weight: normal;
                background: none;
                background-image: url(../images/icon-checkbox-unchecked.svg);
                background-repeat: no-repeat;
                background-size: 16px 16px;
                background-position: top left;
                // padding-left: 35px;
                // padding-right: 10px;
                margin-left: -18px;
                min-height: 16px;
                width: 16px;
                display: inline-block;
                line-height: 1;
                vertical-align: middle;
                cursor: pointer;
            }

            &:checked{
                + label{
                    background-image: url(../images/icon-checkbox-checked.svg);
                    background-position: top left;
                }
            }

            &:focus{
                + label{
                    outline: 1px dotted #212121;
                    outline: 5px auto -webkit-focus-ring-color;
                }
            }

            &:disabled{
                + label{
                    opacity: 0.3;
                    cursor: not-allowed;
                }
            }
        }
    }

    input[type="file"]{
        opacity: 0;
        cursor: pointer;
        font-size: 0;

        +label{
            background: url(../images/icon-add-more.svg) no-repeat;
            background-size: 28px 30px;
            padding-left: 50px;
            // margin-left: -23px;
            height: 30px;
            display: inline-block;
            vertical-align: middle;
            line-height: 30px;
            @include rem-fallback(font-size, 15);
            letter-spacing: 0.5px;
            cursor: pointer;
        }
    }
    input{
        &.inp-txt-vodafone{
            @include rem-fallback(font-size, 18);            
            @include rem-fallback(line-height, 18);
            padding: 10px;
            border: 1px solid #cccccc;
            width: 460px;
            padding: 14px 20px;
            border-radius: 2px;
            box-shadow: none; 
            letter-spacing: 1px;    

            &::placeholder {
                color: #666666;
                opacity: 1; /* Firefox */
            }
              
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
               color: #666666;
            }
              
            &::-ms-input-placeholder { /* Microsoft Edge */
               color: #666666;
            }
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: #666666;
              }
            &::-moz-placeholder { /* Firefox 19+ */
                color: #666666;
            }
            &:-moz-placeholder { /* Firefox 18- */
                color: #666666;
            }
        }    
    }

    .lbl-vodafone{
        display: block;
        color: #999999;
        @include rem-fallback(font-size, 12);        
        @include rem-fallback(line-height, 12);
        margin: 10px 0;
        text-transform: uppercase;
        font-family: $font-primary-bold;
        letter-spacing: 1px;    
    }

    .btn-login{
        min-width: 130px;
    }
}
@mixin mod-inputs-tablet-landscape(){
    /*Start Module: Forms - Tablet Landscape*/
    input{
        &.inp-txt-vodafone{
        }
    }
    /*End Module: Forms - Tablet Landscape*/
}

@mixin mod-inputs-tablet-portrait(){
    /*Start Module: Forms - Tablet Potrait*/
    input{
        &.inp-txt-vodafone{
        }
    }    
    /*End Module: Forms - Tablet Potrait*/
}

@mixin mod-inputs-mobile(){
    /*Start Module: Forms - Tablet Potrait*/
    input{
        &.inp-txt-vodafone{            
            width: 280px;
        }
    }
    /*End Module: Forms - Tablet Potrait*/
}
