@mixin mod-menu(){
    /*Start Module: Menu*/
    .menu{
        font-family: $font-primary;
        position: absolute;
        left: 0;
        width: 100%;
        bottom: -50px;
        
        &--wrap{
            background-color: white;
            @include display-flex();
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
            position: relative;
            margin-bottom: 1.5 * $side-padding-desktop;
        }

        &--item{
            > a{
                border-bottom: 2px solid transparent;
                color: #999999;
                display:inline-block;
                letter-spacing: 0.4px;
                padding: 17px 30px 13px 30px;
            }

            &.active > a{
                color: #333333;
                -webkit-border-image: -webkit-gradient(linear, 0 1, 100% 0, from(#860000), to(#f90000)) 0 1 100%;
                -webkit-border-image: -webkit-linear-gradient(left, #860000, #f90000) 0 1 100%;
                -moz-border-image: -moz-linear-gradient(right, #860000, #f90000) 0 1 100%;
                -o-border-image: -o-linear-gradient(right, #860000, #f90000)0 1 100%;
                border-image: linear-gradient(to right, #860000, #f90000) 0 1 100%;
            }

            > a:hover,
            > a:focus{
                color: #333333;
            }
        }

        &--submenu{
            display: none;
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            padding-top: 12px;

            &.open{
                @include display-flex();
            }

            &--item{
                &:not(:last-child){
                    margin-right: 30px;
                }

                a{
                    @include rem-fallback(font-size, 14);
                    color: black;
                    letter-spacing: 0.5px;

                    &:hover,
                    &:focus{
                        color: black;
                    }
                }

                &.active > a{
                    font-family: $font-primary-bold;
                    color: $theme-color-primary;
                    text-decoration: underline;
                }
            }
        }
    }
    /*End Module: Menu*/
}

@mixin mod-menu-tablet(){
    /*Start Module: Menu - Tablet*/
    .menu{
        position: static;
        left: auto;
        width: auto;
        bottom: auto;
        margin: 0 -20px;
        background-color: black;
        border-top: 1px solid white;
        padding: 45px 0 0;

        &--wrap{
            margin-bottom: 0;
            flex-direction: column;
            background-color: transparent;
        }

        &--item{
            margin-bottom: 35px;

            > a{
                @include rem-fallback(font-size, 30);
                line-height: 1.1;
                letter-spacing: 0.8px;
                text-transform: uppercase;
                padding: 0;
                color: white;
                border-bottom: none;

                &:hover{
                    color: white;
                }
            }

            &.active > a{
                color: white;
            }
        }

        &-open{
            overflow-y: hidden;

            .header{
                position: relative;
                z-index: 1041;
            }
        }

        &-backdrop{
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1040;
            background-color: #000;
            transition: opacity 300ms ease-in-out;
            opacity: 0.5;
        }
    }
    /*End Module: Menu - Tablet*/
}