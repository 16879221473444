@mixin mod-progress(){
    /*Start Module: progess*/
    .progress{
        width: 80px;
        height: 4px;
        margin: 5px;
    }
    .progress-bar{
        background-color: #e60000;
        
    }
   
    /*End Module: progess*/
}