@mixin mod-modals(){
    /*Start Module: Modals*/

    .modal-vodafone{
        .modal{
            &-dialog{
                transform: none !important;
                width: 1000px;
                max-width: 80%;
            }

            &-content{
                border-radius: 0;
                border: none;
                @include prefix(box-shadow, none);
                padding: 40px;

                &.white-bg{
                    .dataTables_wrapper{
                        .dataTables_filter{
                            input{
                                color: $text-color-dark;
                                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                    color: $text-color-dark;
                                }
                                &::-moz-placeholder { /* Firefox 19+ */
                                    color: $text-color-dark;
                                }
                                &:-ms-input-placeholder { /* IE 10+ */
                                    color: $text-color-dark;
                                }
                                &:-moz-placeholder { /* Firefox 18- */
                                    color: $text-color-dark;
                                }
                            }
                        }
                    }
                }
            }

            &-header{
                padding: 0;
                border: none;
                margin-bottom: 30px;

                .close{
                    opacity: 1;
                    margin-top: 3px;
                }
            }

            &-body{
                padding: 0;

                .data-table-container .dataTables_wrapper{
                    margin: 0;

                    .dataTables_filter{
                        float: left;

                        input{
                            @include rem-fallback(font-size, 15);
                            // color: black;
                            margin-left: 0;
                        }
                    }
                }
            }

            &-footer{
                border: none;
                padding: 10px 0 20px;
            }

            &-title{
                font-family: $font-primary-bold;
                font-size: 30px;
                line-height: normal;
                letter-spacing: 0.7px;
            }
        }

        &--login{
            .modal-dialog{
                // max-width: 31%;
                max-width: 440px;
                transform: translate(-50%,-50%) !important;
                -ms-transform: translate(-50%,-50%) !important;
                -webkit-transform: translate(-50%,-50%) !important;
                position: absolute;
                top: 50%;
                left: 50%;
            }

            .modal-content{
                text-align: center;
                padding: 50px 0px;
                // padding: 50px 0px;
                border-radius: 10px;
            }

            &--icon{
                background-image: url('../images/icon-change-password.svg');
                background-size: 40 40px;
                background-repeat: no-repeat;
                display: inline-block;
                width: 40px;
                height: 40px;
            }

            &--heading{        
                font-family: $font-primary-bold;
                @include rem-fallback(font-size, 18); 
                @include rem-fallback(line-height, 18); 
                margin-bottom: 5px;               
            }

            &--text{
                @include rem-fallback(font-size, 16);
                @include rem-fallback(line-height, 16);
                margin-bottom: 40px;
            }

            .modal-footer{
                padding: 0;

                .btn--wrap{
                    justify-content: center;
                    margin-top: 10px;

                    .btn{
                        padding: 10px 40px;

                        &.close{
                            text-shadow: none;
                            opacity: 1;

                            &:hover,&:active,&:focus{
                                opacity: 1;
                            }
                        }
                    }
                }

                span{
                    text-align: center;
                    display: block;
                    margin-bottom: 10px;
                     
                    a{
                        color: #00b0ca;
                        cursor: pointer;
                        padding-bottom: 10px;
                    }
                }
            } 
            
            &--landing{
                .modal-dialog{
                    position: absolute;
                    top: 125px;
                    right: 70px;                    
                    max-width: 400px;
                    border-radius: 5px;
                    margin: 0;

                    .modal-content{
                        padding: 65px 30px 50px;
                        border-radius: 5px;
                        background: transparent url('../images/icon-modal-bg.png') no-repeat;

                        .modal-body{
                            @include display-flex();

                            &--icon{
                                width: 50px;
                                height: 50px;
                                background-image: url('../images/icon-landing-profile.png');
                                background-size: 50px 50px; 
                                background-repeat: no-repeat; 
                                margin-right: 30px;
                            }

                            &--details{
                                &--name,&--title{
                                    color: #333333;   
                                    margin-bottom: 10px;    
                                }
                                
                                &--name{
                                    font-family: $font-primary-bold;
                                    @include rem-fallback(font-size, 22);
                                    @include rem-fallback(line-height, 22);
                                    letter-spacing: 0.8px;                             
                                }

                                &--title{
                                    @include rem-fallback(font-size, 16);
                                    @include rem-fallback(line-height, 16);
                                }

                                &--link{
                                    @include rem-fallback(font-size, 14);
                                    @include rem-fallback(line-height, 16);
                                    color: #00b0ca;
                                }
                            }
                        }
                    }
                }
            }
        } 
    }
    /*End Module: Modals*/
}

@mixin mod-modals-tablet(){
    /*Start Module: Modals - Tablet*/
    .modal-vodafone{
        
    }
    /*End Module: Modals - Tablet*/
}

@mixin mod-modals-tablet-landscape(){
    /*Start Module: Modals - Tablet Landscape*/
    .modal-vodafone{
        &--login{
            .modal-dialog{
            }

            &--landing{
                .modal-dialog{
                    top: 90px;
                    right: 134px;

                    .modal-content{
                        padding: 45px 30px 50px;
                        background: none;
                        background-color: #ffffff; 
                    }
                }  
            }
        }
    }
    /*End Module: Modals - Tablet Landscape*/
}

@mixin mod-modals-tablet-portrait(){
    /*Start Module: Modals - Tablet Portrait*/
    .modal-vodafone{
        &--login{
            .modal-dialog{
            }

            &--landing{
                .modal-dialog{
                    top: 90px;
                    right: 50px;

                    .modal-content{
                        padding: 45px 30px 50px;
                        background: none;
                        background-color: #ffffff; 
                    }
                }  
            }
        }
    }
    /*End Module: Modals - Tablet Portrait*/
}


@mixin mod-modals-mobile(){
    /*Start Module: Modals - Tablet*/
    .modal-vodafone{
        &--login{
            .modal-dialog{
                max-width: 300px;
                margin: 0;
            }

            &--landing{
                .modal-dialog{
                    max-width: 100%;
                    top: 55px;
                    right: 0;

                    .modal-content{
                        padding: 45px 25px 50px;
                        border-radius: 0;
                        background: none;
                        background-color: #ffffff; 
                    }
                }  
            }
        }
    }
    /*End Module: Modals - Tablet*/
}