@mixin mod-forms(){
    /*Start Module: Forms*/
    .frm-vodafone{
        &--row{
            @include display-flex();
            justify-content: space-between;
            
            
            &:not(:last-child){
                margin-bottom: 35px;
            }

            &.row{
                justify-content: flex-start;
                flex-wrap: wrap;

                &:not(:last-child){
                    margin-bottom: 0;
                }

                > .col-md-3{
                    &:nth-child(n+5){
                        margin-top: 35px;
                    }
                }

                > .col-md-4{
                    &:nth-child(n+4){
                        margin-top: 35px;
                    }
                }
            }
        }

        &--col-3{
            width: 33.333%;
            max-width: 335px;

            &:not(:last-child){
                margin-right: 2 * $side-padding-desktop;
            }
        }

        &--col-2{
            width: 50%;
            max-width: 335px;

            &:not(:last-child){
                margin-right: 2 * $side-padding-desktop;
            }
        }

        &--lbl{
            font-size: 15px;
            font-family: $font-primary-bold;
            letter-spacing: 0.4px;
            margin-bottom: 2px;
        }

        fieldset{
            white-space: nowrap;
            margin-top: 10px;
        }

        legend{
            border-bottom: none;
        }

        input[type="radio"].opt-vodafone{
            cursor: pointer;
            
            + label{
                margin-right: 2 * $side-padding-desktop;
                margin-bottom: 0;
                font-weight: normal;
    
                &:last-child{
                    margin-right: 0;
                }
            }
        }

        &--select,&--inp {
            width: 100%;
            height: 2 * $side-padding-desktop;
            border: 1px solid #979797;
            border-radius: 8px;
            padding-left: 14px;
            padding-right: 2 * $side-padding-desktop;
            font-size: 15px;
            letter-spacing: 0.4px;

            &:focus{
                outline: none;
            }
        }

        &--inp{
            &::placeholder{
                color: black;
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: black;
            }

            &::-ms-input-placeholder { /* Microsoft Edge */
                color: black;
            }

            &[data-toggle="modal"]{
                cursor: pointer;
            }

            &[data-toggle="datepicker"]{
                background: url('../images/icon-calendar.svg') no-repeat center right 10px;
                padding-right: 30px;
                max-width: 125px;
                width: calc(50% - 7px);

                + .frm-vodafone--inp[data-toggle="datepicker"]{
                    margin-left: 10px;
                }
            }
        }

        &--select {
            /* for Firefox */
            -moz-appearance: none;
            /* for Chrome */
            -webkit-appearance: none;
            background: url('../images/icon-dropdown.svg') no-repeat center right 10px;
            cursor: pointer;

            &::-ms-expand {/* For IE10 */
                display: none;
            }

            + .chosen-container{
                display: block;

                .chosen-results{
                    @include rem-fallback(font-size, 15);
                    @include rem-fallback(letter-spacing, 0.5);
                    line-height: normal;
                    font-family: $font-primary;
                    color: $text-color-dark;
    
                    li{
                        padding: 12px 0;
                    }
                }

                .chosen-single{
                    min-height: 40px;
                    padding: 1px 30px 1px 14px;
                    border: 1px solid #979797;
                    border-radius: 8px;
                    background-color: #ffffff;
                    line-height: 37px;
    
                    .chosen-search-input{
                        padding: 0;
                    }
                    span{
                        min-width: 25px;
                        font-family: $font-primary;
                    }
                    div{
                        b{
                            &:after{
                                position: absolute;
                                display: block;
                                content: '';
                                width: 16px;
                                height: 9px;
                                top: 16px;
                                left: -10px;
                                background: url('../images/icon-black-dropdown.svg') no-repeat;
                                background-position: right;
                                background-size: 16px 9px;
                            }
                        }
                    }
                }

                &-active{
                    &.chosen-with-drop{
                        .chosen-single{
                            border: 1px solid #979797;
                            border-radius: 8px;
                            background-color: #ffffff;
                        }
                    }
                }
            }
        }
    }
    /*End Module: Forms*/
}

@mixin mod-forms-tablet(){
    /*Start Module: Forms - Tablet*/
    .frm-vodafone{
        &--row{
            &.row{
                > .col-xs-6{
                    &:nth-child(n+3){
                        margin-top: 25px;
                    }
                }
            }
        }
    }
    /*End Module: Forms - Tablet*/
}